.footer {
    margin-top: 30px;

    p {
        font-size: 14px;
    }

    .dark-mode-switch {
        label {
            font-size: 14px;
        }

        .text {
            top: 1px;
            font-size: 14px;
        }
    }
}

.status-badge {
    &.blue {
        .text,
        .icon {
            color: $button-blue;
        }
    }
    &.green {
        .text,
        .icon {
            color: $ktn-green;
        }
    }
    &.yellow {
        .text,
        .icon {
            color: $button-yellow;
        }
    }
    &.red {
        .text,
        .icon {
            color: $button-red;
        }
    }
    .icon {
        margin-right: 10px;
    }
}

.dark-mode {
    .status-badge {
        &.green {
            .text,
            .icon {
                color: $button-green;
            }
        }
    }
}

.dashboard-row {
    .content-block {
        margin-bottom: 0;
    }
}

.dashboard-running-syncs {
    margin-left: 30px;
}

.dashboard-sync-pods {
    width: calc(100% + 30px);
    margin-left: -15px;

    .content-block {
        width: calc(100% / 3 - 30px);
        margin: 0 15px 20px;

        &:last-child {
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 10px;
            font-size: 14px;

            strong {
                @include mainFontSemiBold;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .page-subtitle {
        margin-bottom: 5px;
    }

    .status {
        .icon {
            top: -1px;
            margin-right: 3px;
            font-size: 90%;
        }

        &.green {
            color: $button-green;
        }

        &.blue {
            color: $button-blue;
        }

        &.yellow {
            color: #dc8e00;
        }

        &.red {
            color: $button-red;
        }
    }

    .sync-details {
        padding-right: 100px;

        .current-stability {
            position: absolute;
            top: 0;
            right: 0;
            text-align: center;
        }
    }

    .health-stats {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $form-border;
        overflow: hidden;

        .item {
            text-align: center;
            padding: 0 10px;
            border-right: 1px solid $form-border;

            &:last-child {
                border-right-color: transparent;
            }
        }

        .health-icon {
            font-size: 28px;
        }
    }

    .health-icon {
        font-size: 32px;
        color: #999;

        &.orange {
            color: #dc8e00;
        }
        &.green {
            color: $ktn-medium-green;
        }
        &.red {
            color: $button-red;
        }
    }
}

// chart
.chart-heading {
    padding-right: 15px;
    margin-bottom: 15px;
}

@include screenSize(lgDesktop) {
    .dashboard-sync-pods {
        .content-block {
            width: calc(100% / 2 - 30px);
        }
    }

    .dashboard-row {
        .content-block {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .dashboard-running-syncs {
        margin-left: 0;
    }
}

@include screenSize(lsTablet) {
    .dashboard-sync-pods {
        .content-block {
            width: 100%;
        }
    }
}

// side navigation
.navigation {
    flex: 0 0 225px;
    background-color: $ktn-green;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

    .logo-container {
        padding: 0 15px;
    }

    .logo {
        margin: 15px 0;

        img {
            height: 80px;
            opacity: 0.6;
        }
    }

    .nav-list {
        p,
        a {
            font-size: 13px;
        }
    }

    .nav-group {
        padding: 17px 15px 15px;
        text-transform: uppercase;
        background-color: $ktn-medium-green;

        p {
            font-size: 13px;
            color: white;
        }
    }

    .nav-item {
        a {
            display: flex;
            align-items: center;
            padding: 15px;
            width: 100%;
            text-decoration: none;
            text-transform: uppercase;
            color: white;
            font-size: 14px;
            transition: background-color 0.1s ease-in-out;

            .icon {
                margin-right: 15px;
                font-size: 18px;
            }

            p {
                flex: 1;
            }

            &:hover,
            &.active {
                background-color: $ktn-light-green;
            }
        }
    }

    .menu-icon {
        color: white;
        background-color: transparent;
        border: none;
        padding: 10px 0;
        font-size: 26px;
    }
}

@include screenSize(smDesktop) {
    .navigation {
        position: absolute;
        width: 225px;
        height: 100%;
        z-index: 1;
        transform: translateX(-225px);
        transition: transform 0.3s ease-in-out;

        &.open {
            transform: translateX(0);
        }
    }
}

.datasearch-wrapper {
    .data-search-title {
        width: 170px;
    }

    .table-search {
        flex: 1;
        max-width: 720px;
        padding: 30px 20px;

        .data-search-form {
            display: block;
            width: 100%;

            .form-row {
                justify-content: space-between;
                margin-bottom: 20px;

                .select-container {
                    flex: 1;
                    padding: 0 10px 0 0;

                    &:last-child {
                        padding-right: 0;
                    }
                }

                .form-field {
                    margin-bottom: 0;
                    justify-content: center;

                    input {
                        flex: 1;
                    }

                    .form-error {
                        position: absolute;
                        bottom: -24px;
                    }
                }
            }
        }
    }
}

.modal-overlay {
    .modal-body {
        overflow-x: auto;

        .scroll-container {
            .flow-container {
                width: fit-content;

                .flow-wrapper {
                    padding: 1em;
                    width: fit-content;

                    &.orphans {
                        align-self: start;
                    }

                    .flow-node-group {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    .flow-node {
                        width: 280px;
                        min-height: 200px;
                        border: 2px solid;
                        border-radius: 4px;
                        padding: 1em;
                        margin: 1em 0;
                        box-shadow: $box-shadow;

                        .flow-node-field {
                            font-size: 14px;
                            margin-bottom: 0.25em;
                            cursor: pointer;

                            span {
                                flex: 0.5;
                                overflow-x: hidden;

                                &.field-value {
                                    @include mainFontLight();
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    margin-left: 0.5em;
                                    transition: all 0.2s ease;

                                    .hover-tooltip {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: fit-content;
                                        height: fit-content;
                                        background-color: $secondary-background;
                                        padding: 0.5em;
                                    }
                                }

                                &.field-name {
                                    font-weight: 600;
                                }
                            }
                        }

                        .flow-node-title {
                            background-color: $secondary-background;
                            position: absolute;
                            top: -1em;
                            padding: 4px;
                            font-weight: 600;
                        }
                    }

                    .flow-api-source,
                    .flow-api-dest {
                        width: 160px;
                        min-height: 200px;
                        margin: 1em 0;

                        .flow-api-text-wrapper {
                            width: 10px;

                            .flow-api-text {
                                @include mainFontSemiBold();
                                line-height: 1em;
                                text-align: center;
                                white-space: nowrap;
                                transform-origin: center;
                                font-size: 20px;

                                &.up {
                                    transform: rotate(270deg);
                                }
                                &.down {
                                    transform: rotate(90deg);
                                }
                            }
                        }

                        .api-bracket-container {
                            width: 40px;
                            height: 100%;
                        }
                        .api-arrow-container {
                            width: 60px;
                            height: 100%;
                        }
                    }

                    .flow-node-icon {
                        font-size: 60px;
                        position: absolute;
                        bottom: -25px;
                        right: -25px;
                        &:before {
                            text-shadow: 0px 2px 2px 2px #00000099;
                        }

                        &.fa-check {
                            color: $ktn-medium-green;
                        }
                        &.fa-times {
                            color: $button-red;
                        }
                    }
                    .tooltip-container {
                        bottom: 0px;
                        right: 20px;
                    }

                    .node-arrow-container {
                        width: 80px;
                        flex: 1;

                        &.middle {
                            height: 15px;
                            flex: unset;
                        }
                    }
                }
            }
        }

        .close-modal-button {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            background-color: transparent;
            transition: opacity 250ms ease;
            text-decoration: none;
            color: $main-text;

            &:hover {
                opacity: 0.5;
            }
        }
    }
    &.dark-mode {
        .modal-body {
            background-color: $dark-mode-input-background;

            .scroll-container {
                color: $dark-mode-secondary-text;

                .flow-container {
                    .flow-wrapper {
                        .flow-node {
                            .flow-node-title {
                                background-color: $dark-mode-input-background;
                                color: $dark-mode-primary-text;
                            }
                        }

                        .flow-api-source,
                        .flow-api-dest {
                            .flow-api-text-wrapper {
                                .flow-api-text {
                                    color: $dark-mode-primary-text;
                                }
                            }
                        }

                        .flow-node-icon {
                            &.fa-check {
                                color: $ktn-medium-green;
                            }
                            &.fa-times {
                                color: $button-red;
                            }
                        }

                        .node-arrow-container {
                            width: 80px;
                            flex: 1;

                            &.middle {
                                height: 15px;
                                flex: unset;
                            }
                        }
                    }
                }
            }

            .close-modal-button {
                color: $dark-mode-secondary-text;
            }
        }
    }
}

.breadcrumb {
    margin-bottom: 20px;
    font-size: 14px;

    a {
        color: $ktn-green;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .active {
        color: inherit;
        @include mainFontSemiBold;
    }
}

.page-not-found {
    height: 100%;

    .page-title {
        font-size: 36px;
        margin-bottom: 10px;
    }

    .page-description {
        font-size: 16px;
    }
}

.body {
    .tabs-nav {
        margin-bottom: 1rem;
        .tabs-navitem {
            cursor: pointer;
            display: inline-flex;
            padding: 0.5em 2em;
            margin-right: 1rem;
            margin-bottom: 1em;
            background-color: $secondary-background;
            box-shadow: $box-shadow;

            &.active,
            &:hover {
                background-color: $ktn-medium-green;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.dark-mode {
        .tabs-nav {
            color: $dark-mode-primary-text;

            .tabs-navitem {
                background-color: $dark-mode-option-background;

                &.active,
                &:hover {
                    background-color: $ktn-medium-green;
                }
            }
        }
    }
}

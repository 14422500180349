.page-title,
.page-subtitle,
.page-description {
    color: $ktn-green;

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }

    .tooltip {
        margin-left: 5px;
    }
}

.page-title {
    margin-bottom: 15px;
    font-size: 22px;
    @include mainFontSemiBold;
}

.page-subtitle {
    margin: 15px 0;
    font-size: 18px;
    @include mainFontSemiBold;

    &:first-child {
        margin-top: 0;
    }
}

.page-description {
    margin-bottom: 20px;
    font-size: 14px;
}

.page-title-container {
    .menu-icon {
        margin: 5px 15px 0 0;
        padding: 0;
        background-color: transparent;
        border: none;
        font-size: 22px;
        color: $ktn-green;
    }
}

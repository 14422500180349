@import 'variables.scss';
@import 'reset.scss';
@import 'generic.scss';
@import './pages/index.scss';

html,
body,
#root,
#admin-body {
    height: 100%;
    overflow: hidden;
}

// wrapper
#admin-body {
    display: flex;
    flex-direction: column;
}

#admin-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
}

#admin-content {
    flex: 1;
    padding: 20px;
    overflow: auto;
}

// Main body wrapper
.body {
    background-color: $main-background;

    &.dark-mode {
        //Dark mode styles in here
        background-color: $dark-mode-main-background;

        .footer {
            p {
                color: white;
            }
        }

        .navigation {
            .nav-item {
                p,
                .icon,
                a,
                a:visited,
                a:active,
                a:focus {
                    color: $dark-mode-primary-text;
                    opacity: 1;
                }
            }

            .nav-group {
                p {
                    color: $dark-mode-primary-text;
                }
            }
        }

        .page-title,
        .page-subtitle,
        .page-description,
        .form-label {
            color: $dark-mode-primary-text;
        }

        .page-title-container {
            .menu-icon {
                color: white;
            }
        }

        .form-input,
        .form-select {
            background-color: $dark-mode-input-background;
            color: $dark-mode-secondary-text;
            border-color: $dark-mode-border;

            i {
                color: $dark-mode-secondary-text;
            }

            &.error {
                border-color: $form-error;
            }

            &.multi-select {
                .option {
                    background-color: $dark-mode-secondary-text;
                    color: $dark-mode-input-background;

                    i {
                        color: $dark-mode-input-background;
                    }
                }
            }
        }

        .form-checkbox .content p,
        .form-radio .content p {
            color: $dark-mode-primary-text;
        }

        .form-checkbox .content {
            .outer-box {
                border-color: $dark-mode-border;
                background-color: $dark-mode-input-background;
            }

            .inner-box {
                &.active {
                    color: $ktn-light-green;
                }
            }
        }

        .form-radio .content {
            .outer-box {
                border-color: $dark-mode-border;
                background-color: $dark-mode-input-background;
            }

            .inner-box {
                &.active {
                    background-color: $ktn-light-green;
                }
            }
        }

        .form-datepicker {
            i {
                color: $dark-mode-secondary-text;
            }
        }

        .form-select-options {
            background-color: $dark-mode-input-background;
            color: $dark-mode-secondary-text;
            border-color: $dark-mode-border;

            .options-list {
                .option {
                    &:hover,
                    &.active {
                        background-color: $dark-mode-nav-background;
                    }
                }
            }
        }

        .checkbox {
            .text {
                color: $dark-mode-primary-text;
            }

            .outer {
                background-color: $ktn-light-green;
                color: $dark-mode-main-background;
            }

            input {
                &:checked {
                    + label,
                    + input + label {
                        .outer {
                            background-color: $ktn-light-green;
                        }
                    }
                }
            }
        }

        .table-search {
            input {
                background-color: $dark-mode-input-background;
                border: 1px solid $dark-mode-border;
                color: white;

                &::placeholder {
                    color: $dark-mode-secondary-text;
                }
            }
        }

        .table-pagination {
            .page-selection {
                .title,
                .count {
                    color: $dark-mode-secondary-text;
                }

                .form-select {
                    border-color: $dark-mode-border;
                }
            }

            .page-links {
                button {
                    color: $dark-mode-secondary-text;
                }
            }
        }

        table {
            th {
                color: $dark-mode-primary-text;
            }
            tr {
                color: $dark-mode-secondary-text;

                &.selectable {
                    &:hover,
                    &.selected {
                        background-color: #3a3a3a;
                    }
                }
            }

            .table-loading {
                color: $dark-mode-primary-text;

                .overlay {
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        .button {
            &.blue {
                background-color: $dark-mode-accent-colour;
            }

            &.green {
                background-color: $ktn-light-green;
            }

            &.grey {
                background-color: $dark-mode-secondary-text;
            }

            .icon {
                color: white;
            }
        }

        .content-block {
            background-color: $dark-mode-nav-background;
            box-shadow: 0px 1px 1px 1px $dark-mode-dark-background;

            label {
                color: $dark-mode-primary-text;
            }

            p {
                color: $dark-mode-secondary-text;
            }
        }

        .details-section {
            background-color: $dark-mode-header;
            color: $dark-mode-primary-text;
        }

        .breadcrumb {
            color: white;

            a {
                color: $ktn-light-green;
            }

            .active {
                color: white;
            }
        }

        .dashboard-sync-pods {
            .status {
                &.green {
                    color: $ktn-light-green;
                }

                &.blue {
                    color: $dark-mode-accent-colour;
                }

                &.yellow {
                    color: orange;
                }

                &.red {
                    color: $button-red;
                }
            }

            .health-icon {
                &.orange {
                    color: orange;
                }
            }
        }

        .data-block {
            p,
            i {
                color: white;
            }
        }

        .custom-scroll {
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $dark-mode-border;
            }
        }

        // end of dark mode
    }
}

.modal-overlay {
    &.dark-mode {
        .custom-scroll {
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $dark-mode-border;
            }
        }
    }

    // end of dark mode
}

// login form
.login-form-wrapper {
    min-height: 100%;

    .auth-logo {
        height: 120px;
        margin-bottom: 30px;
    }

    .login-form {
        width: 600px;
        padding: 20px;
        box-shadow: $box-shadow;
    }

    form {
        width: 100%;
    }
}

// create header
.create-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;

    .page-title {
        margin-bottom: 0;
    }

    .button {
        margin-left: 15px;
    }
}

// loading
.loading {
    text-align: center;
    font-size: 22px;
}

// single section
.single-section {
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }
}

@import 'components/footer.scss';
@import 'components/nav.scss';
@import 'components/contentBlock.scss';
@import 'components/dataCheck.scss';
@import 'components/breadcrumb.scss';
@import 'components/tooltip.scss';

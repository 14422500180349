.body,
.modal-overlay {
    .tooltip-container {
        position: absolute;
        overflow: visible;
        width: 1px;
        height: 1px;
        align-items: center;
        z-index: 100;
        pointer-events: none;

        &.hidden {
            display: none;
        }

        .tooltip-pointer {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            color: $main-background;

            &.up {
                border-bottom: 15px solid;
            }
            &.down {
                border-top: 15px solid;
            }
        }

        .tooltip-content {
            box-shadow: $box-shadow;
            padding: 1em;
            background-color: $main-background;
            border-radius: 4px;
            width: 240px;
            font-size: 16px;
            color: $main-text;
            @include mainFont();

            .title {
                margin-bottom: 0.5em;

                strong {
                    font-weight: 600;
                }
            }

            p {
                line-height: 1.25;
            }
        }
    }
    &.dark-mode {
        .tooltip-container {
            .tooltip-pointer {
                color: $dark-mode-border;
            }

            .tooltip-content {
                background-color: $dark-mode-border;
                color: $dark-mode-primary-text;
            }
        }
    }
}

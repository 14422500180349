// table
table {
    width: 100%;
    font-size: 14px;

    thead {
        tr {
            border-top: none;
            border-bottom-width: 2px;
        }
    }

    tr {
        border: 1px solid $form-border;
        border: {
            left: none;
            right: none;
        }

        &.selectable {
            cursor: pointer;

            &:hover,
            &.selected {
                background-color: #dee8f3;
            }
        }
    }

    th,
    td {
        padding: 15px;
        text-align: left;
    }

    th {
        cursor: default;
        user-select: none;
        @include mainFontSemiBold;

        i {
            margin-right: 10px;
        }
    }

    .button {
        padding: 6px 25px;
        font-size: 13px;
    }

    .loading-row {
        font-size: 22px;
        text-align: center;
    }

    .no-data-row {
        td {
            padding: 20px 15px;
            text-align: center;
        }
    }

    .table-selection {
        p {
            margin-bottom: 5px;
        }
    }

    .tooltip {
        margin-left: 5px;
    }
}

// pagination
.table-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;

    .page-links {
        display: flex;

        li {
            margin-right: 10px;
        }

        button {
            background-color: transparent;
            font-size: 16px;
            color: $main-text;
            outline: none;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.3;
            }
        }
    }

    .page-selection {
        display: flex;
        align-items: center;
        margin-right: 40px;

        label {
            font-size: 14px;
        }

        .title {
            margin-right: 15px;
        }

        .count {
            margin-left: 15px;
        }
    }
}

// search
.table-search {
    display: flex;
    align-items: center;
    padding: 15px 0;
    padding-left: 10px;

    &.no-flex {
        display: block;
    }

    .icon {
        font-size: 18px;
        color: #aaa;
        margin-right: 5px;
    }

    input {
        width: 100%;
        padding: 5px 10px;
        border: none;
        outline: none;
        @include mainFont();
        font-size: 14px;
        line-height: 1.5;
        color: $main-text;
        border-radius: 3px;
        &::placeholder {
            color: #aaa;
        }
    }
}

// table top areas
.table-top-filter {
    .table-search {
        flex: 1;
    }

    .table-pagination {
        width: 550px;
        margin: 10px 0;
    }
}

.table-loading {
    position: absolute;
    top: 51px;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 200px;

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #eeeeee88;
    }
    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 44px;
        text-align: center;
    }
}

/* Firefox only */
@-moz-document url-prefix() {
    .table-loading {
        top: 0;
    }
}
